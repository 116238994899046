
// import { computed, reactive, watch, ref, defineComponent, onMounted } from 'vue'
import { defineComponent, ref, reactive, watch } from 'vue'
import { formatTime } from '@/utils/dateTime'
import { formatterDollars } from '@/utils/formatter'
import { useNotification } from '@/composables'
// import Pagination from '@/components/common/Pagination.vue'
import axios from '@/services/axios'
import { getOrderStatusColor } from '@/utils/orderUtils'
import OrderSourceTag from '@/components/orders/display/OrderSourceTag.vue'

export default defineComponent({
  components: {
    OrderSourceTag,
    // Pagination,
    // ArrowDown,
  },
  setup() {
    const { error, success, warning } = useNotification()
    const dialogVisible = ref<boolean>(false)
    const customerEmail = ref('')
    const isLoading = ref<boolean>(true)
    const baseResource = 'orders/customers'

    const customers = ref([])

    const params = reactive<any>({
      page: 1,
      limit: 10,
    })

    const getOrdersOfCustomer = async (email: string) => {
      try {
        params.email = email
        const res = await axios.get(`${baseResource}/detail`, { params })
        if (res?.data) {
          customers.value = res.data.data
        }
      } catch (error) {
        console.log(error)
        return null
      }
    }

    const toggle = async (email: string) => {
      customerEmail.value = email
      await getOrdersOfCustomer(email)
      isLoading.value = false
      dialogVisible.value = !dialogVisible.value
    }

    const close = () => {
      dialogVisible.value = false
    }

    const columns = [
      {
        title: 'Created',
        dataIndex: 'createdAt',
        minWidth: '10',
      },
      {
        title: 'Order Name',
        dataIndex: 'referenceId1',
        minWidth: '15',
      },
      {
        title: 'Total price',
        dataIndex: 'grandTotal',
        minWidth: '8',
      },
      {
        title: 'Transaction ID',
        dataIndex: 'transactionId',
        minWidth: '15',
      },
      {
        title: 'Financial',
        dataIndex: 'storeFinancialStatus',
        minWidth: '10',
      },
      {
        title: 'FFM Status',
        dataIndex: 'status',
        minWidth: '10',
      },
      {
        title: 'FFM cost',
        dataIndex: 'sellerAmount',
        minWidth: '8',
      },
      {
        title: 'Items',
        dataIndex: 'items',
        minWidth: '25',
      },
      {
        title: 'Source',
        dataIndex: 'source',
        minWidth: '8',
      },
    ]

    return {
      formatTime,
      formatterDollars,
      toggle,
      close,
      dialogVisible,
      params,
      isLoading,
      customers,
      customerEmail,
      getOrdersOfCustomer,
      columns,
      getOrderStatusColor,
    }
  },
})
